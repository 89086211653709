import { create } from "zustand";
import { devtools } from "zustand/middleware";

export type UiTools = {
  openedModalSettingsId: string | null;
  dragQuestionContentId: string | null;
  desireToOpenABranchingModal: string | null;
  editSomeQuestion: string | null;
  canCreatePublic: boolean;
  whyCantCreatePublic: Record<string, WhyCantCreatePublic>; //ид вопроса и список претензий к нему
  openModalInfoWhyCantCreate: boolean;
  deleteNodeId: string | null;
  showConfirmLeaveModal: boolean;
  someWorkBackend: boolean;
  nextStep: number;
  modalQuestionParentContentId: string;
  modalQuestionTargetContentId: string;
  openedModalQuestions: boolean;
};

export type WhyCantCreatePublic = {
  name: string;
  problems: string[];
};

const initialState: UiTools = {
  openedModalSettingsId: null as null,
  dragQuestionContentId: null,
  desireToOpenABranchingModal: null as null,
  editSomeQuestion: null as null,
  canCreatePublic: false,
  whyCantCreatePublic: {},
  openModalInfoWhyCantCreate: false,
  deleteNodeId: null,
  showConfirmLeaveModal: false,
  someWorkBackend: false,
  nextStep: -1,
  modalQuestionParentContentId: "",
  modalQuestionTargetContentId: "",
  openedModalQuestions: false,
};

export const useUiTools = create<UiTools>()(
  devtools(() => initialState, {
    name: "UiTools",
    enabled: process.env.NODE_ENV === "development",
    trace: process.env.NODE_ENV === "development",
  }),
);

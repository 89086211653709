import { AnyTypedQuizQuestion } from "@model/questionTypes/shared";
import { QuestionType } from "./question";

export interface EditQuestionRequest {
  id: number;
  title?: string;
  desc?: string;
  type?: QuestionType;
  required?: boolean;
  page?: number;
  content: string;
}

export interface EditQuestionResponse {
  updated: number;
}

export function questionToEditQuestionRequest(
  question: AnyTypedQuizQuestion,
): EditQuestionRequest {
  return {
    id: question.backendId,
    title: question.title,
    desc: question.description,
    type: question.type,
    required: question.required,
    page: question.page,
    content: JSON.stringify(question.content),
  };
}

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PenaLogo from "../../ui_kit/PenaLogo";
import SectionStyled from "./SectionStyled";
import { styled } from "@mui/material/styles";
import { useMediaQuery, useTheme } from "@mui/material";

export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  return (
    <SectionStyled
      tag={"footer"}
      bg={"#252734"}
      mwidth={"1160px"}
      sxsect={{
        justifyContent: "space-around",
      }}
      sxcont={{
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        minHeight: "236px",
        padding: isMobile
          ? "40px 16px 74px 16px"
          : isTablet
            ? "56px 40px"
            : "56px 20px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "50%",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
          gap: isMobile ? "65px" : "120px",
        }}
      >
        <Box
          sx={{
            width: "170px",
            height: "48px",
            backgroundSize: "contain",
            marginTop: isMobile ? undefined : isTablet ? "15px" : 0,
          }}
        >
          <svg
            width="124"
            height="48"
            viewBox="0 0 124 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.9015 2.29269C12.8451 1.70698 9.35663 7.13845 6.11187 11.0646C3.26427 14.5102 1.144 18.402 0.897728 22.8672C0.635712 27.6177 1.49746 32.5651 4.73385 36.0493C8.06749 39.6381 13.0612 41.9057 17.9015 41.1726C22.3399 40.5003 24.3038 35.6263 27.6082 32.5846C31.1962 29.2819 37.8808 27.7458 37.7881 22.8672C37.6952 17.9811 30.6403 17.0247 27.2457 13.5127C23.7296 9.87488 22.9244 2.87452 17.9015 2.29269Z"
              fill="#7E2AEA"
            />
            <ellipse
              cx="30.4815"
              cy="39.3174"
              rx="2.79008"
              ry="2.79008"
              fill="#7E2AEA"
            />
            <circle cx="27.6918" cy="8.36074" r="1.06289" fill="#7E2AEA" />
            <path
              d="M44.6914 21.725C44.3815 18.712 42.964 15.9213 40.7136 13.894C38.4632 11.8666 35.5403 10.7469 32.5114 10.752C32.0814 10.7522 31.6516 10.7747 31.2239 10.8194C28.2122 11.1356 25.4243 12.5558 23.3979 14.8061C21.3715 17.0563 20.2501 19.9773 20.25 23.0055V23.0055V40.5105H25.5015V33.0709C27.5548 34.4993 29.997 35.2631 32.4983 35.259C32.9283 35.2589 33.3581 35.2364 33.7858 35.1916C35.3861 35.0234 36.9377 34.5417 38.3519 33.7738C39.7661 33.006 41.0152 31.9671 42.0279 30.7166C43.0405 29.466 43.797 28.0282 44.254 26.4852C44.711 24.9423 44.8596 23.3245 44.6914 21.7241V21.725ZM37.9467 27.4141C37.3698 28.1307 36.6565 28.7258 35.8481 29.1648C35.0397 29.6038 34.1522 29.878 33.237 29.9716C32.9916 29.9971 32.745 30.01 32.4983 30.0101C30.9101 30.0087 29.3696 29.4673 28.1296 28.475C26.8896 27.4826 26.0238 26.0982 25.6743 24.5489C25.3249 22.9996 25.5125 21.3776 26.2063 19.949C26.9002 18.5204 28.0591 17.3701 29.4929 16.687C30.9267 16.0038 32.5501 15.8284 34.0967 16.1895C35.6433 16.5506 37.0212 17.4267 38.0042 18.6741C38.9873 19.9215 39.517 21.466 39.5066 23.0542C39.4961 24.6424 38.9461 26.1798 37.9467 27.4141Z"
              fill="#F2F3F7"
            />
            <path
              d="M58.3944 10.7529C57.9643 10.7531 57.5346 10.7756 57.1069 10.8203C53.9863 11.149 51.1108 12.6622 49.0728 15.0482C47.0349 17.4341 45.99 20.511 46.1534 23.6445C46.3168 26.7781 47.676 29.7296 49.9511 31.8906C52.2261 34.0517 55.2434 35.2577 58.3813 35.26C58.8113 35.2598 59.241 35.2373 59.6688 35.1926C61.9187 34.9531 64.0589 34.0972 65.8536 32.7192C67.6482 31.3412 69.0277 29.4945 69.8401 27.3827H63.8542L63.8297 27.4133C63.063 28.3611 62.0621 29.0922 60.9261 29.5344C59.7901 29.9766 58.5583 30.1146 57.3527 29.9347C56.147 29.7547 55.0092 29.2632 54.0517 28.5086C53.0943 27.754 52.3505 26.7625 51.8939 25.6322H70.3617C70.7505 23.8431 70.7342 21.9897 70.3138 20.2078C69.8934 18.4259 69.0797 16.7606 67.9323 15.3339C66.7848 13.9073 65.3327 12.7554 63.6824 11.9628C62.032 11.1701 60.2252 10.7567 58.3944 10.7529V10.7529ZM51.8939 20.3807C52.3719 19.204 53.1603 18.1791 54.175 17.4152C55.1897 16.6512 56.3927 16.177 57.6557 16.043C57.9011 16.0175 58.1477 16.0046 58.3944 16.0044C59.7892 16.0021 61.1528 16.4178 62.3091 17.1978C63.4655 17.9779 64.3615 19.0865 64.8818 20.3807H51.8939Z"
              fill="#F2F3F7"
            />
            <path
              d="M83.3332 10.752C81.3261 10.7488 79.3571 11.3007 77.6441 12.3467V10.752H72.3926V35.259H77.6441V21.6926C77.6441 20.1838 78.2435 18.7367 79.3104 17.6698C80.3773 16.6029 81.8244 16.0035 83.3332 16.0035C84.8421 16.0035 86.2891 16.6029 87.3561 17.6698C88.423 18.7367 89.0224 20.1838 89.0224 21.6926V35.259H94.2739V21.6926C94.2739 18.791 93.1212 16.0082 91.0694 13.9564C89.0177 11.9046 86.2349 10.752 83.3332 10.752Z"
              fill="#F2F3F7"
            />
            <path
              d="M120.533 24.5723V10.753H115.282V12.9411C113.229 11.512 110.786 10.7482 108.285 10.753C107.855 10.7531 107.425 10.7756 106.998 10.8203C103.877 11.149 101.001 12.6622 98.9635 15.0482C96.9255 17.4341 95.8806 20.511 96.044 23.6446C96.2074 26.7781 97.5667 29.7296 99.8417 31.8907C102.117 34.0517 105.134 35.2577 108.272 35.26C108.702 35.2598 109.132 35.2373 109.559 35.1926C112.612 34.8802 115.433 33.4262 117.459 31.1218C119.032 33.2274 121.312 34.6963 123.879 35.26V29.7547C122.883 29.3034 122.037 28.5746 121.444 27.6556C120.851 26.7366 120.535 25.6662 120.533 24.5723ZM113.72 27.4134C113.143 28.1298 112.43 28.7247 111.622 29.1637C110.813 29.6027 109.926 29.8771 109.011 29.9709C108.765 29.9963 108.519 30.0092 108.272 30.0094C106.478 30.0093 104.752 29.3205 103.451 28.0851C102.15 26.8497 101.373 25.162 101.28 23.3702C101.187 21.5784 101.785 19.8193 102.951 18.4559C104.117 17.0925 105.763 16.2287 107.547 16.043C107.792 16.0175 108.039 16.0046 108.285 16.0045C109.607 16.0056 110.902 16.3809 112.019 17.0871C113.137 17.7933 114.032 18.8015 114.6 19.9949C115.169 21.1884 115.388 22.5185 115.232 23.8313C115.077 25.1441 114.552 26.386 113.72 27.4134Z"
              fill="#F2F3F7"
            />
            <path
              d="M101.904 37.9189V40.8434C102.395 40.2683 102.983 39.9807 103.667 39.9807C104.018 39.9807 104.334 40.0459 104.616 40.1761C104.899 40.3063 105.11 40.4727 105.251 40.6752C105.396 40.8778 105.494 41.1021 105.544 41.348C105.599 41.594 105.626 41.9756 105.626 42.4929V45.8731H104.101V42.8293C104.101 42.2252 104.072 41.8418 104.014 41.679C103.956 41.5162 103.853 41.3878 103.705 41.2938C103.56 41.1961 103.378 41.1473 103.157 41.1473C102.904 41.1473 102.678 41.2088 102.479 41.3318C102.28 41.4547 102.133 41.641 102.039 41.8906C101.949 42.1366 101.904 42.5019 101.904 42.9866V45.8731H100.379V37.9189H101.904Z"
              fill="#F2F3F7"
            />
            <path
              d="M110.97 45.8731V45.0104C110.76 45.3179 110.484 45.5603 110.14 45.7375C109.8 45.9147 109.44 46.0034 109.06 46.0034C108.673 46.0034 108.326 45.9184 108.018 45.7484C107.711 45.5783 107.488 45.3396 107.351 45.0321C107.214 44.7247 107.145 44.2997 107.145 43.7571V40.111H108.67V42.7587C108.67 43.569 108.697 44.0664 108.751 44.2508C108.809 44.4317 108.912 44.5764 109.06 44.6849C109.208 44.7898 109.397 44.8422 109.624 44.8422C109.885 44.8422 110.118 44.7717 110.324 44.6306C110.531 44.486 110.672 44.3087 110.748 44.0989C110.824 43.8855 110.862 43.3664 110.862 42.5417V40.111H112.386V45.8731H110.97Z"
              fill="#F2F3F7"
            />
            <path
              d="M113.905 45.8731V37.9189H115.43V40.7838C115.9 40.2484 116.457 39.9807 117.101 39.9807C117.803 39.9807 118.383 40.2358 118.843 40.7458C119.302 41.2522 119.532 41.981 119.532 42.9324C119.532 43.9162 119.297 44.674 118.827 45.2058C118.36 45.7375 117.792 46.0034 117.123 46.0034C116.794 46.0034 116.468 45.922 116.146 45.7592C115.828 45.5928 115.553 45.3487 115.322 45.0267V45.8731H113.905ZM115.419 42.8673C115.419 43.4641 115.513 43.9054 115.701 44.1912C115.965 44.5963 116.316 44.7988 116.754 44.7988C117.09 44.7988 117.376 44.656 117.611 44.3702C117.85 44.0808 117.969 43.6269 117.969 43.0083C117.969 42.35 117.85 41.8762 117.611 41.5868C117.372 41.2938 117.067 41.1473 116.694 41.1473C116.329 41.1473 116.025 41.2902 115.783 41.5759C115.54 41.8581 115.419 42.2885 115.419 42.8673Z"
              fill="#F2F3F7"
            />
          </svg>
          <Typography color={"#727074"} fontSize={"14px"}>
            ООО Пена © 2024
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "457px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              button: { color: "#F2F3F7", textTransform: "none" },
            }}
          >
            <Button
              variant="text"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "white",
                justifyContent: "start",
              }}
              href={"https://hub.pena.digital/docs/oferta"}
              target="_blank"
            >
              Пользовательское соглашение
            </Button>
            <Button
              variant="text"
              sx={{
                fontSize: "16px",
                fontWeight: 500,
                color: "white",
                justifyContent: "start",
              }}
              href={"https://hub.pena.digital/docs/privacy"}
              target="_blank"
            >
              Политика конфиденциальности
            </Button>
          </Box>
        </Box>
      </Box>
    </SectionStyled>
  );
}

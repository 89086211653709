import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { mutate } from "swr";

import { makeRequest } from "@api/makeRequest";
import { getDiscounts } from "@api/discounts";

import {
  clearUserData,
  OriginalUserAccount,
  setUserAccount,
  useUserStore,
} from "@root/user";
import { parseAxiosError } from "@utils/parse-error";
import { useUserAccountFetcher } from "@utils/hooks/useUserAccountFetcher";
import type { Discount } from "@model/discounts";
import { clearAuthToken, createUserAccount, devlog } from "@frontend/kitui";
import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";
import { activatePromocode } from "@api/promocode";
import { getAccount } from "@api/user";

export function CheckFastlink() {
  const user = useUserStore();
  const userId = useUserStore((state) => state.userId);
  const navigate = useNavigate();
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [askToChange, setAskToChange] = useState(false);
  const [promocode, setPromocode] = useState("");
  console.log(user.userAccount, user.customerAccount);
  useEffect(() => {
    const get = async () => {
      if (!user.userId) {
        return;
      }

      const [discounts] = await getDiscounts(user.userId);

      if (discounts?.length) {
        setDiscounts(discounts);
      }
    };

    get();
  }, []);

  const fetchPromocode = async () => {
    if (promocode.length > 0) {
      const [greetings, activationError] = await activatePromocode(promocode);

      if (activationError || !greetings) {
        enqueueSnackbar(activationError);
        localStorage.setItem("fl", "");

        return;
      }

      enqueueSnackbar(
        greetings !== "" ? greetings : "Промокод успешно активирован",
      );
      localStorage.setItem("fl", "");
      const [responseAccount, accountError] = await getAccount();

      if (accountError || !responseAccount) {
        return;
      }

      setUserAccount(responseAccount);
      mutate("discounts");
      return greetings;
    }
  };

  useLayoutEffect(() => {
    //Промокод может быть или в урл адресе или в ЛС
    const params = new URLSearchParams(window.location.search);
    const flURL = params.get("fl");

    if (flURL !== null) {
      localStorage.setItem("fl", flURL);
      var url = new URL(window.location.href);
      url.searchParams.delete("fl");
      history.pushState(null, document.title, url);
    }

    const flLS = localStorage.getItem("fl");

    if (flLS !== null && flLS.length > 0) {
      setPromocode(flLS);

      if (user.userId !== null) {
        //У нас есть промокод и юзер авторизован. Проверяем есть ли у него применённый промокод
        //Проверяем были ли запросы на аккаунт и кастомер аккаунт
        if (user.userAccount !== null && user.customerAccount !== null) {
          if (discounts?.find((e) => e.Condition.User === user.userId)) {
            setAskToChange(true);
          } else {
            fetchPromocode();
          }
        }
      }
    }
  }, [
    user.userId,
    discounts,
    user.customerAccount?.createdAt,
    user.userAccount?.created_at,
  ]);

  return (
    <Modal
      open={askToChange}
      onClose={() => setAskToChange(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography textAlign="center" variant="h6" component="h2">
          Заменить текущий промокод?
        </Typography>
        <Box
          sx={{
            display: "flex",
            mt: "20px",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            sx={{ margin: "5px", minWidth: " auto" }}
            variant="pena-contained-dark"
            onClick={() => {
              fetchPromocode();
              setAskToChange(false);
            }}
          >
            Да
          </Button>
          <Button
            sx={{ margin: "5px", minWidth: " auto" }}
            variant="pena-contained-dark"
            onClick={() => {
              setAskToChange(false);
              localStorage.setItem("fl", "");
            }}
          >
            Нет
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

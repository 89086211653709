import { useNavigate } from "react-router-dom";
import { setCash } from "@root/cash";
import { useUserStore } from "@root/user";

import { cartApi } from "@api/cart";
import { currencyFormatter } from "../../pages/Tariffs/tariffsUtils/currencyFormatter";

const MINUTE = 1000 * 60;

export const useAfterpay = () => {
  const userId = useUserStore((state) => state.userId);
  const navigate = useNavigate();

  const checkPayment = () => {
    const redirectUrl = new URL(window.location.href);
    const paymentUserId = redirectUrl.searchParams.get("userid");
    redirectUrl.searchParams.set("afterpay", "false");
    navigate(redirectUrl);

    if (userId !== paymentUserId) {
      return;
    }

    const payCartPendingRequestDeadline = localStorage.getItem(
      "payCartPendingRequestDeadline",
    );
    const deadline = payCartPendingRequestDeadline
      ? Number(payCartPendingRequestDeadline)
      : Date.now() + 20 * MINUTE;

    localStorage.setItem("payCartPendingRequestDeadline", deadline.toString());

    let tryCount = 0;
    tryPayCart();

    async function tryPayCart() {
      tryCount += 1;

      const [data, payCartError] = await cartApi.pay();
      if (data !== null)
        setCash(
          currencyFormatter.format(Number(data.wallet.cash) / 100),
          Number(data.wallet.cash),
          Number(data.wallet.cash) / 100,
        );

      if (!payCartError || Date.now() > deadline) {
        localStorage.removeItem("payCartPendingRequestDeadline");
        return;
      }

      setTimeout(tryPayCart, tryCount > 5 ? MINUTE : MINUTE / 6);
    }
  };

  const checkParams = () => {
    const afterpay = new URLSearchParams(window.location.search).get(
      "afterpay",
    );

    const payCartPendingRequestDeadline = localStorage.getItem(
      "payCartPendingRequestDeadline",
    );
    const deadline = payCartPendingRequestDeadline
      ? Number(payCartPendingRequestDeadline)
      : Date.now() + 20 * MINUTE;
    if (Date.now() <= deadline) {
      if (afterpay) {
        checkPayment();
      }
    }
  };

  setInterval(checkParams, 5000);
  checkParams();
};

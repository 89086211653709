import { makeRequest } from "@api/makeRequest";

import { parseAxiosError } from "@utils/parse-error";

import type { UserAccount } from "@frontend/kitui";

const API_URL = `${process.env.REACT_APP_DOMAIN}/customer/v1.0.1/cart`;

const payCart = async (): Promise<[UserAccount | null, string?]> => {
  try {
    const payCartResponse = await makeRequest<never, UserAccount>({
      method: "POST",
      url: `${API_URL}/pay`,
      useToken: true,
    });

    return [payCartResponse];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось оплатить товар из корзины. ${error}`];
  }
};

const addCartItem = async (
  id: string,
): Promise<[UserAccount | null, string?]> => {
  try {
    const addedItem = await makeRequest<never, UserAccount>({
      method: "PATCH",
      url: `${API_URL}?id=${id}`,
    });

    return [addedItem];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось добавить товар в корзину. ${error}`];
  }
};

const deleteCartItem = async (
  id: string,
): Promise<[UserAccount | null, string?]> => {
  try {
    const deletedItem = await makeRequest<never, UserAccount>({
      method: "DELETE",
      url: `${API_URL}?id=${id}`,
    });

    return [deletedItem];
  } catch (nativeError) {
    const [error] = parseAxiosError(nativeError);

    return [null, `Не удалось удалить товар из корзины. ${error}`];
  }
};

export const cartApi = {
  pay: payCart,
  add: addCartItem,
  delete: deleteCartItem,
};

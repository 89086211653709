import { create } from "zustand";
import { devtools } from "zustand/middleware";

type Wallet = { cashString: string; cashCop: number; cashRub: number };
export const useWallet = create<Wallet>()(
  devtools(() => ({ cashString: "", cashCop: 0, cashRub: 0 })),
);

export const setCash = (
  cashString: string,
  cashCop: number,
  cashRub: number,
) => {
  useWallet.setState({ cashString, cashCop, cashRub });
};

import { QuestionType } from "@model/question/question";
import { AnyTypedQuizQuestion } from "@model/questionTypes/shared";
import { QUIZ_QUESTION_DATE } from "./date";
import { QUIZ_QUESTION_EMOJI } from "./emoji";
import { QUIZ_QUESTION_FILE } from "./file";
import { QUIZ_QUESTION_IMAGES } from "./images";
import { QUIZ_QUESTION_NUMBER } from "./number";
import { QUIZ_QUESTION_PAGE } from "./page";
import { QUIZ_QUESTION_RATING } from "./rating";
import { QUIZ_QUESTION_SELECT } from "./select";
import { QUIZ_QUESTION_TEXT } from "./text";
import { QUIZ_QUESTION_VARIANT } from "./variant";
import { QUIZ_QUESTION_VARIMG } from "./varimg";
import { QUIZ_QUESTION_RESULT } from "./result";

export const defaultQuestionByType: Record<
  QuestionType,
  Omit<AnyTypedQuizQuestion, "id" | "backendId">
> = {
  date: QUIZ_QUESTION_DATE,
  emoji: QUIZ_QUESTION_EMOJI,
  file: QUIZ_QUESTION_FILE,
  images: QUIZ_QUESTION_IMAGES,
  number: QUIZ_QUESTION_NUMBER,
  page: QUIZ_QUESTION_PAGE,
  rating: QUIZ_QUESTION_RATING,
  select: QUIZ_QUESTION_SELECT,
  text: QUIZ_QUESTION_TEXT,
  variant: QUIZ_QUESTION_VARIANT,
  varimg: QUIZ_QUESTION_VARIMG,
  result: QUIZ_QUESTION_RESULT,
} as const;

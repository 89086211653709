import { useUiTools } from "./store";

export const cleardragQuestionContentId = () => {
  useUiTools.setState({ dragQuestionContentId: null });
};
export const updateDragQuestionContentId = (contentId?: string) => {
  useUiTools.setState({ dragQuestionContentId: contentId ? contentId : null });
};

let UDTOABM: ReturnType<typeof setTimeout>;
export const updateDesireToOpenABranchingModal = (contentId: string) => {
  useUiTools.setState({ desireToOpenABranchingModal: contentId });
  clearTimeout(UDTOABM);
  UDTOABM = setTimeout(() => {
    useUiTools.setState({ desireToOpenABranchingModal: null });
  }, 7000);
};
export const clearDesireToOpenABranchingModal = () => {
  useUiTools.setState({ desireToOpenABranchingModal: null });
};

export const updateEditSomeQuestion = (contentId?: string) => {
  useUiTools.setState({
    editSomeQuestion: contentId === undefined ? null : contentId,
  });
};

export const updateOpenedModalSettingsId = (id?: string) =>
  useUiTools.setState({ openedModalSettingsId: id ? id : null });

export const updateCanCreatePublic = (can: boolean) =>
  useUiTools.setState({ canCreatePublic: can });

export const updateModalInfoWhyCantCreate = (can: boolean) =>
  useUiTools.setState({ openModalInfoWhyCantCreate: can });
export const updateDeleteId = (deleteNodeId: string | null = null) =>
  useUiTools.setState({ deleteNodeId });
export const setShowConfirmLeaveModal = (showConfirmLeaveModal: boolean) =>
  useUiTools.setState({ showConfirmLeaveModal });

export const updateSomeWorkBackend = (someWorkBackend: boolean) =>
  useUiTools.setState({ someWorkBackend });

export const updateNextStep = (nextStep: number) =>
  useUiTools.setState({ nextStep });

export const setModalQuestionParentContentId = (
  modalQuestionParentContentId: string,
) => useUiTools.setState({ modalQuestionParentContentId });
export const setModalQuestionTargetContentId = (
  modalQuestionTargetContentId: string,
) => useUiTools.setState({ modalQuestionTargetContentId });
export const setOpenedModalQuestions = (open: boolean) =>
  useUiTools.setState({ openedModalQuestions: open });

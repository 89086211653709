import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionFile } from "../model/questionTypes/file";

export const QUIZ_QUESTION_FILE: Omit<QuizQuestionFile, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "file",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    required: false,
    innerNameCheck: false,
    innerName: "",
    type: "picture",
  },
};

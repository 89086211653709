import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionVariant } from "../model/questionTypes/variant";
import { nanoid } from "nanoid";

export const QUIZ_QUESTION_VARIANT: Omit<
  QuizQuestionVariant,
  "id" | "backendId"
> = {
  ...QUIZ_QUESTION_BASE,
  type: "variant",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    largeCheck: false,
    multi: false,
    own: false,
    innerNameCheck: false,
    required: false,
    innerName: "",
    variants: [
      {
        id: nanoid(),
        answer: "",
        extendedText: "",
        hints: "",
        originalImageUrl: "",
      },
    ],
  },
};

import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionPage } from "../model/questionTypes/page";

export const QUIZ_QUESTION_PAGE: Omit<QuizQuestionPage, "id" | "backendId"> = {
  ...QUIZ_QUESTION_BASE,
  type: "page",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    innerNameCheck: false,
    innerName: "",
    text: "",
    picture: "",
    originalPicture: "",
    video: "",
  },
};

import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionSelect } from "../model/questionTypes/select";
import { nanoid } from "nanoid";

export const QUIZ_QUESTION_SELECT: Omit<
  QuizQuestionSelect,
  "id" | "backendId"
> = {
  ...QUIZ_QUESTION_BASE,
  type: "select",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    multi: false,
    required: false,
    innerNameCheck: false,
    innerName: "",
    default: "",
    variants: [
      {
        id: nanoid(),
        answer: "",
        extendedText: "",
        hints: "",
        originalImageUrl: "",
      },
    ],
  },
};

import { QuestionType } from "@model/question/question";
import type { QuizQuestionDate } from "./date";
import type { QuizQuestionEmoji } from "./emoji";
import type { QuizQuestionFile } from "./file";
import type { QuizQuestionImages } from "./images";
import type { QuizQuestionNumber } from "./number";
import type { QuizQuestionPage } from "./page";
import type { QuizQuestionRating } from "./rating";
import type { QuizQuestionSelect } from "./select";
import type { QuizQuestionText } from "./text";
import type { QuizQuestionVariant } from "./variant";
import type { QuizQuestionVarImg } from "./varimg";
import type { QuizQuestionResult } from "./result";
import { nanoid } from "nanoid";

export interface QuestionBranchingRuleMain {
  next: string;
  or: boolean;
  rules: {
    question: string; //id родителя (пока что)
    answers: string[];
  }[];
}
export interface QuestionBranchingRule {
  children: string[];
  //список условий
  main: QuestionBranchingRuleMain[];
  parentId: string | null | "root";
  default: string;
}

export interface QuestionHint {
  /** Текст подсказки */
  text: string;
  /** URL видео подсказки */
  video: string;
}

export type QuestionVariant = {
  id: string;
  /** Текст */
  answer: string;
  /** Текст подсказки */
  hints: string;
  /** Дополнительное поле для текста, emoji, ссылки на картинку */
  extendedText: string;
  /** Оригинал изображения (до кропа) */
  originalImageUrl: string;
};

export interface QuizQuestionBase {
  backendId: number;
  /** Stable id, generated on client */
  id: string;
  quizId: number;
  title: string;
  description: string;
  page: number;
  type?: QuestionType | null;
  expanded: boolean;
  openedModalSettings: boolean;
  deleted: boolean;
  deleteTimeoutId: number;
  required: boolean;
  content: {
    id: string;
    hint: QuestionHint;
    rule: QuestionBranchingRule;
    back: string;
    originalBack: string;
    autofill: boolean;
  };
}

export interface UntypedQuizQuestion {
  type: null;
  id: string;
  quizId: number;
  title: string;
  description: string;
  expanded: boolean;
  deleted: boolean;
}

export type AnyTypedQuizQuestion =
  | QuizQuestionVariant
  | QuizQuestionImages
  | QuizQuestionVarImg
  | QuizQuestionEmoji
  | QuizQuestionText
  | QuizQuestionSelect
  | QuizQuestionDate
  | QuizQuestionNumber
  | QuizQuestionFile
  | QuizQuestionPage
  | QuizQuestionRating
  | QuizQuestionResult;

  export type AllTypesQuestion = 
  | AnyTypedQuizQuestion
  | UntypedQuizQuestion;

type FilterQuestionsWithVariants<T> = T extends {
  content: { variants: QuestionVariant[] };
}
  ? T
  : never;

export type QuizQuestionsWithVariants =
  FilterQuestionsWithVariants<AnyTypedQuizQuestion>;

export const createBranchingRuleMain: (
  targetId: string,
  parentId: string,
) => QuestionBranchingRuleMain = (targetId, parentId) => ({
  next: targetId,
  or: false,
  rules: [
    {
      question: parentId,
      answers: [] as string[],
    },
  ],
});
export const createQuestionVariant: () => QuestionVariant = () => ({
  id: nanoid(),
  answer: "",
  extendedText: "",
  hints: "",
  originalImageUrl: "",
});

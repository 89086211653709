import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { SidebarModal } from "./SidebarModal";

import BackArrowIcon from "@icons/BackArrowIcon";
import { Settings } from "./icons/Settings";
import { Pencil } from "./icons/Pencil";
import { ArrowDown } from "./icons/ArrowDown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setCurrentStep, updateQuiz } from "@root/quizes/actions";
import { useCurrentQuiz } from "@root/quizes/hooks";
import { LogoutButton } from "@ui_kit/LogoutButton";
import PencilCircleIcon from "@icons/PencilCircleIcon";
import { quizSetupSteps } from "@model/quizSettings";
import { updateNextStep } from "@root/uiTools/actions";
import { handleLogoutClick } from "@utils/HandleLogoutClick";

interface SidebarIconProps {
  height: string;
  width: string;
  color: string;
}

interface Iprops {
  open: boolean;
  changePage: (step: number) => void;
  setHeightSitebar: any;
  scrollDown: boolean;
}

export const SidebarMobile: FC<Iprops> = ({
  open,
  changePage,
  setHeightSitebar,
  scrollDown,
}) => {
  const theme = useTheme();
  const isWrappSidebar = useMediaQuery(theme.breakpoints.down(400));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inputOpen, setInputOpen] = useState<boolean>(false);
  const quiz = useCurrentQuiz();
  const [inputValue, setInputValue] = useState(quiz.name);
  const ref = useRef<HTMLInputElement | null>(null);
  const heightSidebar = useRef(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const observer = useRef(
    new ResizeObserver((entries) => {
      const { height } = entries[0].contentRect;
      setHeightSitebar(height);
    }),
  );

  useEffect(() => {
    if (heightSidebar.current) {
      observer.current.observe(heightSidebar.current);
    }
  }, [heightSidebar, observer]);

  const handleClick = (event: ChangeEvent<HTMLDivElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const clickInput = (event: MouseEvent) => {
    if (ref.current && !ref.current?.contains(event.target as Node))
      setInputOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", clickInput);
    return () => {
      document.removeEventListener("mousedown", clickInput);
    };
  }, []);

  const changeMenuItem = (index: number) => {
    if (!pathname.startsWith("/edit")) {
      navigate("/edit");
    }

    updateNextStep(index);
    changePage(index);
  };
  const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popper" : "";
  return (
    <Box
      ref={heightSidebar}
      sx={{
        display: open ? "flex" : "none",
        flexDirection: "column",
        width: "100%",
        minHeight: "134px",
        padding: "20px 16px 16px 16px",
        background: "#333647",
        borderTop: "1px solid  #9A9AAF",
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
        transitionDuration: "200ms",
        position: "fixed",
        top: "51px",
        zIndex: theme.zIndex.drawer + 1,
        transition: "transform 0.3s",
        transform: scrollDown ? "translateY(-200px)" : undefined,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", position: "relative" }}>
        <Link to="/list">
          <IconButton sx={{ p: "6px" }}>
            <BackArrowIcon color={"white"} />
          </IconButton>
        </Link>

        <Box
          sx={{
            ml: "15px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "12px", color: "#9A9AAF" }}>
              Название
            </Typography>
            {inputOpen ? (
              <FormControl fullWidth variant="standard">
                <TextField
                  ref={ref}
                  value={inputValue}
                  onChange={(e) => {
                    if (e.target.value.length <= 200) {
                      setInputValue(e.target.value);
                    }
                    updateQuiz(quiz.id, (quiz) => {
                      quiz.name = e.target.value;
                    });
                  }}
                  fullWidth
                  id="project-name"
                  placeholder="Название проекта"
                  sx={{
                    width: "85%",
                    "& .MuiInputBase-root": {
                      height: "34px",
                      borderRadius: "8px",
                      p: 0,
                    },
                  }}
                  inputProps={{
                    sx: {
                      height: "20px",
                      borderRadius: "8px",
                      fontSize: "16px",
                      lineHeight: "20px",
                      p: "7px",
                      color: "white",
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                  }}
                />
              </FormControl>
            ) : (
              <Typography color={"white"} sx={{ wordBreak: "break-word" }}>
                {quiz.name}
              </Typography>
            )}
          </Box>
          <IconButton onClick={() => setInputOpen(true)} sx={{ mt: "10px" }}>
            <Pencil
              style={{
                position: "absolute",
                right: "0",
                color: "white",
                fontSize: "24px",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
          flexWrap: "wrap",
          gap: "5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <LogoutButton
            onClick={() => {
              navigate("/");
              handleLogoutClick();
            }}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid #9A9AAF",
            }}
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {quizSetupSteps.map(({ sidebarIcon }, index) => (
              <Box
                key={index}
                onClick={() => changeMenuItem(index)}
                sx={{
                  cursor: "pointer",
                  width: isWrappSidebar ? "30px" : "36px",
                  height: isWrappSidebar ? "30px" : "36px",
                  background: "#262835",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "8px",
                }}
              >
                {React.createElement(
                  sidebarIcon as React.FC<SidebarIconProps>,
                  {
                    height: isWrappSidebar ? "25px" : "32px",
                    width: isWrappSidebar ? "25px" : "32px",
                    color: `${theme.palette.brightPurple.main}`,
                  },
                )}
              </Box>
            ))}
            <Box
              onClick={() => {
                navigate("/design");
                setCurrentStep(15);
              }}
              sx={{
                cursor: "pointer",
                width: isWrappSidebar ? "30px" : "36px",
                height: isWrappSidebar ? "30px" : "36px",
                background: "#262835",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <PencilCircleIcon
                color={theme.palette.brightPurple.main}
                height={isWrappSidebar ? "25px" : "32px"}
                width={isWrappSidebar ? "25px" : "32px"}
              />
            </Box>
          </Box>
        </Box>
        <Box
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            pr: "10px",
            pl: "5px",
            width: "60px",
            height: "44px",
            background: "#262835",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "8px",
            border: "1px solid #FFFFFF66",
            marginLeft: "0",
          }}
        >
          <Settings
            style={{ color: "#974BFA", fontSize: "24px", marginLeft: "5px" }}
          />
          <ArrowDown style={{ color: "#F2F3F7" }} />
        </Box>
      </Box>
      <SidebarModal
        open={openPopper}
        anchorEl={anchorEl}
        handleClick={handleClick}
        id={id}
        changePage={changePage}
      />
    </Box>
  );
};

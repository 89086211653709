import CollapseMenuIcon from "@icons/CollapseMenuIcon";
import GearIcon from "@icons/GearIcon";
import PencilCircleIcon from "@icons/PencilCircleIcon";
import PuzzlePieceIcon from "@icons/PuzzlePieceIcon";
import TagIcon from "@icons/TagIcon";
import { quizSetupSteps } from "@model/quizSettings";
import { Box, IconButton, List, Typography, useTheme } from "@mui/material";
import { useQuizStore } from "@root/quizes/store";
import { useState } from "react";
import MenuItem from "../MenuItem";
import { useCurrentQuiz } from "@root/quizes/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentStep } from "@root/quizes/actions";
import { updateNextStep } from "@root/uiTools/actions";

const quizSettingsMenuItems = [
  [TagIcon, "Дополнения"],
  [PencilCircleIcon, "Дизайн"],
  [PuzzlePieceIcon, "Интеграции"],
  [GearIcon, "Настройки"],
] as const;

type SidebarProps = {
  changePage: (step: number) => void;
  disableCollapse?: boolean;
};

export default function Sidebar({ changePage, disableCollapse }: SidebarProps) {
  const theme = useTheme();
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const currentStep = useQuizStore((state) => state.currentStep);
  const quiz = useCurrentQuiz();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const changeMenuItem = (index: number) => {
    if (!pathname.startsWith("/edit")) {
      navigate("/edit");
    }

    updateNextStep(index);
    changePage(index);
  };

  const handleMenuCollapseToggle = () => setIsMenuCollapsed((prev) => !prev);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.lightPurple.main,
        minWidth: isMenuCollapsed ? "80px" : "230px",
        width: isMenuCollapsed ? "80px" : "230px",
        height: "calc(100vh - 80px)",
        display: "flex",
        flexDirection: "column",
        py: "19px",
        transitionProperty: "width, min-width",
        transitionDuration: "200ms",
        overflow: "hidden",
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          pl: isMenuCollapsed ? undefined : "16px",
          pr: isMenuCollapsed ? undefined : "8px",
          mb: isMenuCollapsed ? "5px" : undefined,
          alignItems: "center",
          justifyContent: isMenuCollapsed ? "center" : undefined,
        }}
      >
        {!isMenuCollapsed && (
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "20px",
              fontWeight: 500,
              color: theme.palette.grey2.main,
            }}
          >
            {`Создание ${quiz.config.type}`}
          </Typography>
        )}
        {!disableCollapse && (
          <IconButton
            onClick={handleMenuCollapseToggle}
            sx={{ ml: isMenuCollapsed ? undefined : "auto" }}
          >
            <CollapseMenuIcon
              height="16px"
              width="16px"
              color={theme.palette.grey2.main}
              transform={isMenuCollapsed ? "rotate(180deg)" : ""}
            />
          </IconButton>
        )}
      </Box>
      <List disablePadding>
        {quizSetupSteps.map((menuItem, index) => {
          const Icon = menuItem.sidebarIcon;

          return (
            <MenuItem
              onClick={() => changeMenuItem(index)}
              key={index}
              text={menuItem.sidebarText}
              isCollapsed={isMenuCollapsed}
              isActive={currentStep === index && pathname.startsWith("/edit")}
              disabled={
                pathname.startsWith("/edit")
                  ? false
                  : quiz === undefined
                    ? true
                    : quiz?.config.type === null
              }
              icon={
                <Icon
                  color={
                    currentStep === index && pathname.startsWith("/edit")
                      ? theme.palette.brightPurple.main
                      : isMenuCollapsed
                        ? "white"
                        : theme.palette.grey2.main
                  }
                  height={isMenuCollapsed ? "35px" : "24px"}
                  width={isMenuCollapsed ? "35px" : "24px"}
                />
              }
            />
          );
        })}
      </List>
      {!isMenuCollapsed && (
        <Typography
          sx={{
            px: "16px",
            mt: "16px",
            mb: "6px",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
            color: theme.palette.grey2.main,
          }}
        >
          {`Настройки ${quiz.config.type}`}
        </Typography>
      )}
      <List disablePadding>
        <MenuItem
          onClick={() => {
            navigate("/design");
            setCurrentStep(15);
          }}
          text={"Дизайн"}
          isCollapsed={isMenuCollapsed}
          isActive={pathname.startsWith("/design")}
          disabled={
            pathname.startsWith("/design")
              ? false
              : quiz === undefined
                ? true
                : quiz?.config.type === null
          }
          icon={
            <PencilCircleIcon
              color={
                pathname.startsWith("/design")
                  ? theme.palette.brightPurple.main
                  : isMenuCollapsed
                    ? "white"
                    : theme.palette.grey2.main
              }
              height={isMenuCollapsed ? "35px" : "24px"}
              width={isMenuCollapsed ? "35px" : "24px"}
            />
          }
        />
        <MenuItem
          onClick={() => {
            navigate("/integrations");
            setCurrentStep(16);
          }}
          text={"Интеграции"}
          isCollapsed={isMenuCollapsed}
          isActive={pathname.startsWith("/integrations")}
          disabled={
            pathname.startsWith("/integrations")
              ? false
              : quiz === undefined
                ? true
                : quiz?.config.type === null
          }
          icon={
            <PuzzlePieceIcon
              color={
                pathname.startsWith("/integrations")
                  ? theme.palette.brightPurple.main
                  : isMenuCollapsed
                    ? "white"
                    : theme.palette.grey2.main
              }
              height={isMenuCollapsed ? "35px" : "24px"}
              width={isMenuCollapsed ? "35px" : "24px"}
            />
          }
        />
      </List>
    </Box>
  );
}

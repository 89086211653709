import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionImages } from "../model/questionTypes/images";
import { nanoid } from "nanoid";

export const QUIZ_QUESTION_IMAGES: Omit<
  QuizQuestionImages,
  "id" | "backendId"
> = {
  ...QUIZ_QUESTION_BASE,
  type: "images",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    own: false,
    multi: false,
    xy: "1:1",
    innerNameCheck: false,
    innerName: "",
    large: false,
    format: "carousel",
    required: false,
    variants: [
      {
        id: nanoid(),
        answer: "",
        extendedText: "",
        originalImageUrl: "",
        hints: "",
      },
    ],
    largeCheck: false,
  },
};

import type {
  QuizQuestionBase,
  QuestionBranchingRuleMain,
} from "../model/questionTypes/shared";

export const QUIZ_QUESTION_BASE: Omit<QuizQuestionBase, "id" | "backendId"> = {
  quizId: 0,
  description: "",
  page: 0,
  title: "",
  expanded: true,
  openedModalSettings: false,
  required: false,
  deleted: false,
  deleteTimeoutId: 0,
  content: {
    id: "",
    hint: {
      text: "",
      video: "",
    },
    rule: {
      children: [],
      main: [] as QuestionBranchingRuleMain[],
      parentId: "",
      default: "",
    },
    back: "",
    originalBack: "",
    autofill: false,
  },
};

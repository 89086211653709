import { QUIZ_QUESTION_BASE } from "./base";

import type { QuizQuestionResult } from "../model/questionTypes/result";
import { nanoid } from "nanoid";

export const QUIZ_QUESTION_RESULT: Omit<
  QuizQuestionResult,
  "id" | "backendId"
> = {
  ...QUIZ_QUESTION_BASE,
  type: "result",
  content: {
    ...QUIZ_QUESTION_BASE.content,
    video: "",
    innerName: "",
    text: "",
    price: [0],
    useImage: true,
    usage: true,
    redirect: "",
  },
};
